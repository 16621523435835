// styles.js

export const darkTheme = {
  headerBg: '#000',
  headerColor: '#ccc',
  diagramBg: '#111',
  controlPanelBg: '#333',
  textColor: '#ccc',
  buttonBg: '#555',
  buttonColor: '#ccc',
  resizeBg: '#555',
  fretColor: '#ccc',
  stringColor: '#ccc',
  labelColor: '#ccc',
  nodeColor: 'red',
  diagramButton: "#ccc"
};

export const lightTheme = {
  headerBg: '#000',
  headerColor: '#ccc',
  diagramBg: '#fff',
  controlPanelBg: '#333',
  textColor: '#ccc',
  buttonBg: '#ccc',
  buttonColor: '#333',
  resizeBg: '#ccc',
  fretColor: '#000',
  stringColor: '#000',
  labelColor: '#000',
  nodeColor: 'red',
  diagramButton: "#000"
};

export const headerStyle = (theme, isStacked) => ({
  minHeight: isStacked ? '60px' : '100%',
  minWidth: isStacked ? '100%' : '30px', 
  backgroundColor: theme.headerBg,
  color: theme.headerColor,
  display: 'flex',
  flexDirection: isStacked ? 'row' : 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
});

export const appContainerStyle = (isStacked) => ({
  display: 'flex',
  flexDirection: isStacked ? 'column' : 'row-reverse',
  height: '100dvh',
  width: '100dvw',
  justifyContent: 'left',
});

export const headerGroupStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
};

export const contentStyle = (isStacked) => ({
  display: 'flex',
  flexDirection: isStacked ? 'column' : 'row-reverse',
  height: isStacked ? 'calc(100dvh - 60px)' : '100dvh', // full height minus header
  width: '100dvw',
});

export const diagramFrameStyle = (theme) => ({
  backgroundColor: theme.diagramBg,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'min-content',
  minWidth: 'min-content',
});

export const controlPanelStyle = (theme, isStacked, panelSize) => ({
  backgroundColor: theme.controlPanelBg,
  width: isStacked ? '100%' : `${panelSize}px`,
  height: isStacked ? `${panelSize}px` : '100%',
  display: 'flex',
  position: 'relative',
  color: theme.textColor,
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: isStacked ? 'row' : 'column',
  minHeight: 'min-content',
  minWidth: 'min-content',
  overflowY: 'auto',
  padding: '10px',
  gap: '10px', 
  boxSizing: 'border-box',
});

export const controlPanelGroup = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px',
  padding: '10px',
  backgroundColor: '#444',
  borderRadius: '10px',
};

export const resizerStyle = (theme, isStacked) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: isStacked ? '100%' : '20px',
  minHeight: isStacked ? '20px' : '100%',
  cursor: isStacked ? 'ns-resize' : 'ew-resize',
  color: theme.textColor,
  backgroundColor: theme.resizeBg,
});

export const buttonStyle = (theme) => ({
  backgroundColor: theme.buttonBg,
  color: theme.buttonColor,
  border: 'none',
  cursor: 'pointer',
  padding: '5px 10px',
  borderRadius: '5px',
});

export const sliderStyle = {
  width: '80%',
  margin: '10px 0',
};

export const layoutButtonStyle = {
  position: 'absolute',
  right: 0,
  paddingRight: '10px',
  top: 0,
  paddingTop: '10px',
};

export const gridStyle = {
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr min-content',
  gridTemplateRows: 'min-content 1fr min-content',
  width: '100%',
  height: '100%',
};

export const centerCellStyle = {
  gridColumn: '2',
  gridRow: '2',
};

export const topRightCellStyle = {
  gridColumn: '3',
  gridRow: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#777',
};

export const cellStyle = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  minWidth: '60px',
  minHeight: '60px',
};

export const defaultPositions = {
  'Corner Thin String Nut': { gridColumn: '1', gridRow: '1' },
  'Thin String Side Container': { gridColumn: '2', gridRow: '1' },
  'Corner Thin String Bridge': { gridColumn: '3', gridRow: '1' },
  'Nut Side Container': { gridColumn: '1', gridRow: '2' },
  'Fretboard Container': { gridColumn: '2', gridRow: '2' },
  'Bridge Side Container': { gridColumn: '3', gridRow: '2' },
  'Corner Thick String Nut': { gridColumn: '1', gridRow: '3' },
  'Thick String Side Container': { gridColumn: '2', gridRow: '3' },
  'Corner Thick String Bridge': { gridColumn: '3', gridRow: '3' },
};

export const rotatedPositions = {
  'Corner Thin String Nut': { gridColumn: '3', gridRow: '1' },
  'Thin String Side Container': { gridColumn: '3', gridRow: '2' },
  'Corner Thin String Bridge': { gridColumn: '3', gridRow: '3' },
  'Nut Side Container': { gridColumn: '2', gridRow: '1' },
  'Fretboard Container': { gridColumn: '2', gridRow: '2' },
  'Bridge Side Container': { gridColumn: '2', gridRow: '3' },
  'Corner Thick String Nut': { gridColumn: '1', gridRow: '1' },
  'Thick String Side Container': { gridColumn: '1', gridRow: '2' },
  'Corner Thick String Bridge': { gridColumn: '1', gridRow: '3' },
};

export const flippedPositions = {
  'Corner Thin String Nut': { gridColumn: '1', gridRow: '3' },
  'Thin String Side Container': { gridColumn: '2', gridRow: '3' },
  'Corner Thin String Bridge': { gridColumn: '3', gridRow: '3' },
  'Nut Side Container': { gridColumn: '1', gridRow: '2' },
  'Fretboard Container': { gridColumn: '2', gridRow: '2' },
  'Bridge Side Container': { gridColumn: '3', gridRow: '2' },
  'Corner Thick String Nut': { gridColumn: '1', gridRow: '1' },
  'Thick String Side Container': { gridColumn: '2', gridRow: '1' },
  'Corner Thick String Bridge': { gridColumn: '3', gridRow: '1' },
};

export const rotatedFlippedPositions = {
  'Corner Thin String Nut': { gridColumn: '3', gridRow: '3' },
  'Thin String Side Container': { gridColumn: '2', gridRow: '3' },
  'Corner Thin String Bridge': { gridColumn: '1', gridRow: '3' },
  'Nut Side Container': { gridColumn: '3', gridRow: '2' },
  'Fretboard Container': { gridColumn: '2', gridRow: '2' },
  'Bridge Side Container': { gridColumn: '1', gridRow: '2' },
  'Corner Thick String Nut': { gridColumn: '3', gridRow: '1' },
  'Thick String Side Container': { gridColumn: '2', gridRow: '1' },
  'Corner Thick String Bridge': { gridColumn: '1', gridRow: '1' },
};
