import React, { useState, useEffect, useRef } from 'react';
import {
  darkTheme,
  lightTheme,
  headerStyle,
  appContainerStyle,
  contentStyle,
  diagramFrameStyle,
  controlPanelStyle,
  resizerStyle,
  buttonStyle,
  sliderStyle,
  gridStyle,
  centerCellStyle,
  cellStyle,
  layoutButtonStyle,
  controlPanelGroup,
  defaultPositions,
  rotatedPositions,
  flippedPositions,
  rotatedFlippedPositions,
  topRightCellStyle,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate, faMoon, faWrench, faPlay, faMusic, faShapes, faPen } from '@fortawesome/free-solid-svg-icons';
import { faTableLayout, faRotateReverse, faGripLines, faGripLinesVertical } from '@fortawesome/pro-solid-svg-icons';


const App = () => {
  const [isStacked, setIsStacked] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isRotated, setIsRotated] = useState(false);
  const [isFlipped] = useState(false);
  const [isLeftHanded, setIsLeftHanded] = useState(false);
  const [panelSize, setPanelSize] = useState(300);
  const [frets, setFrets] = useState(12);
  const fretRefs = useRef([]);
  const [strings, setStrings] = useState(6);
  const [showNut, setShowNut] = useState(true);
  const fretboardRef = useRef(null);
  const [scaleLength, setScaleLength] = useState(100); // Default scale length
  const [showFretLabelsThinside, setShowFretLabelsThinside] = useState(true);
  const [showFretLabelsThickside, setShowFretLabelsThickside] = useState(true);
  const [showStringLabelsNutside, setShowStringLabelsNutside] = useState(true);
  const [showStringLabelsBridgeside, setShowStringLabelsBridgeside] = useState(true);
  const LabelVisible = showFretLabelsThinside || showFretLabelsThickside || showStringLabelsNutside || showStringLabelsBridgeside;
  const [showNodes, setShowNodes] = useState(false);
  const [stringSizeBase, setStringSizeBase] = useState(1);

  const stringSizeIncrement = stringSizeBase * 0.5;
  const fretSize = stringSizeBase * 2;

  const controlPanelRef = useRef(null);

  const theme = isDarkMode ? darkTheme : lightTheme;

  const positions = isRotated
    ? isFlipped
      ? rotatedFlippedPositions
      : rotatedPositions
    : isFlipped
    ? flippedPositions
    : defaultPositions;

  const toggleLayout = () => {
    setIsStacked(!isStacked);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleRotation = () => {
    setIsRotated(!isRotated);
  };


  const toggleLeftHanded = () => {
    setIsLeftHanded(!isLeftHanded);
  };
  
  const toggleShowNut = () => {
    setShowNut(!showNut);
  };

  const handleFretsChange = (e) => {
    setFrets(parseInt(e.target.value));
  };

  const handleStringsChange = (e) => {
    setStrings(parseInt(e.target.value));
  };

  const handleStringSizeBaseChange = (e) => {
    setStringSizeBase(parseFloat(e.target.value));
  };

  const toggleShowNodes = () => {
    setShowNodes(!showNodes);
  };

useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth <= 550) {
      setIsStacked(true);
    }
  };

  window.addEventListener('resize', handleResize);
  handleResize(); // Call on mount to check initial size

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  
useEffect(() => {
    const handleMouseMove = (e) => {
        if (isStacked) {
            setPanelSize(window.innerHeight - e.clientY - 70);
        } else {
            setPanelSize(e.clientX - 60); // Inverted behavior
        }
    };

    const handleTouchMove = (e) => {
        e.preventDefault(); // Prevent scrolling and other default touch behaviors
        const touch = e.touches[0]; // Get the first touch
        if (isStacked) {
            setPanelSize(window.innerHeight - touch.clientY - 70);
        } else {
            setPanelSize(touch.clientX - 10); // Inverted behavior
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('touchmove', handleTouchMove, { passive: false });
        document.removeEventListener('touchend', handleMouseUp);
    };

    const handleMouseDown = (e) => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleTouchStart = (e) => {
        e.preventDefault(); // Prevents the default action of touch
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        document.addEventListener('touchend', handleMouseUp);
    };

    const resizer = controlPanelRef.current.previousElementSibling;
    resizer.addEventListener('mousedown', handleMouseDown);
    resizer.addEventListener('touchstart', handleTouchStart);

    return () => {
        // Clean up all event listeners
        resizer.removeEventListener('mousedown', handleMouseDown);
        resizer.removeEventListener('touchstart', handleTouchStart);
        // Make sure to remove event listeners in case the event was still active during component unmount
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleMouseUp);
    };
}, [isStacked]);

  // FRET POSITION AND WIDTH

  useEffect(() => {
    if (fretboardRef.current) {
      const fretboardRect = fretboardRef.current.getBoundingClientRect();
      const scaleLengthPx = isRotated ? fretboardRect.height : fretboardRect.width;
      let fretPositionsAndSizes = [];
      let totalFretWidth = 0;

      // Calculate fret positions and measure sizes in one pass
      for (let i = 0; i <= frets; i++) {
        let position, size;

        const currentPosition = scaleLengthPx * (1 - 1 / Math.pow(2, i / 12));
        position = (currentPosition / scaleLengthPx) * 100; // Position in percentage
        size = i === 0 ? currentPosition : currentPosition - (scaleLengthPx * (1 - 1 / Math.pow(2, i / 12)));


        // Measure actual fret size if ref is available
        if (fretRefs.current[i]) {
          const rect = fretRefs.current[i].getBoundingClientRect();
          size = isRotated ? rect.height : rect.width;
        }

        fretPositionsAndSizes.push({ position, size });
        totalFretWidth += size;
      }

      // Adjust positions based on measured sizes
      const totalGapWidth = scaleLengthPx - totalFretWidth;
      let currentPosition = 0;

        let gapSizes = [];
        let totalGapSizeBeforeScaling = 0;

        for (let i = 0; i < frets; i++) {
          const gapSize = 1 / Math.pow(2, i / 12) - 1 / Math.pow(2, (i + 1) / 12);
          gapSizes.push(gapSize);
          totalGapSizeBeforeScaling += gapSize;
        }

        const scaleFactor = totalGapWidth / totalGapSizeBeforeScaling;
        gapSizes = gapSizes.map(size => size * scaleFactor);

        fretPositionsAndSizes = fretPositionsAndSizes.map((fret, index) => {
          const adjustedPosition = (currentPosition / scaleLengthPx) * 100;
          currentPosition += fret.size + (gapSizes[index] || 0);
          return { ...fret, position: adjustedPosition };
        });
      

      // Add isRotated flag to each fret object
      fretPositionsAndSizes = fretPositionsAndSizes.map(fret => ({ ...fret, isRotated }));

      setFretPositionsAndSizes(fretPositionsAndSizes);
    }
  }, [frets, scaleLength, showNut, isRotated, isLeftHanded]);

  useEffect(() => {
    const updateScaleLength = () => {
      if (fretboardRef.current) {
        const rect = fretboardRef.current.getBoundingClientRect();
        setScaleLength(isRotated ? rect.height : rect.width);
      }
    };

    updateScaleLength(); // Update on mount

    window.addEventListener('resize', updateScaleLength); // Update on resize

    return () => {
      window.removeEventListener('resize', updateScaleLength);
    };
  }, [isRotated]);

  const [fretPositionsAndSizes, setFretPositionsAndSizes] = useState([]);

  const Strings = ({ numStrings = 6, isRotated, theme }) => {
    const [strings, setStrings] = useState([]);
    const containerRef = useRef(null);

    useEffect(() => {
      const generateStrings = () => {
        const containerSize = isRotated
          ? containerRef.current ? containerRef.current.offsetWidth : window.innerWidth
          : containerRef.current ? containerRef.current.offsetHeight : window.innerHeight;

        let totalStringSize = 0;
        const initialStrings = [];

        // Calculate initial string sizes
        for (let i = 0; i < numStrings; i++) {
          const size = stringSizeBase + (stringSizeIncrement * i); // Start with the thinnest string
          initialStrings.push({ size });
          totalStringSize += size;
        }

        // Calculate total gap size
        const totalGapSize = containerSize - totalStringSize;
        const gapSize = totalGapSize / (numStrings - 1);

        // Position strings with even gaps
        let currentPosition = 0;
        const positionedStrings = initialStrings.map((string, index) => {
          let position;
          if (isRotated) {
            if (index === 0) {
              position = 100 - (string.size / containerSize * 100); // Right align the thinnest string
            } else if (index === numStrings - 1) {
              position = 0; // Left align the thickest string
            } else {
              // Position intermediate strings from right to left
              position = 100 - ((currentPosition + string.size) / containerSize * 100);
            }
          } else {
            position = (currentPosition / containerSize) * 100; // Normal positioning when not rotated
          }
          currentPosition += string.size + (index < numStrings - 1 ? gapSize : 0);
          return { ...string, position };
        });

        // Reverse the order when rotated or left-handed to go from thickest to thinnest
        setStrings(isRotated ? positionedStrings.reverse() : positionedStrings);
      };

      generateStrings();
      window.addEventListener('resize', generateStrings);
      return () => window.removeEventListener('resize', generateStrings);
    }, [numStrings, isRotated]);

    return (
      <div
        ref={containerRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {strings.map((string, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: isRotated ? `${string.position}%` : 0,
              right: isRotated ? undefined : 0,
              top: isRotated ? 0 : `${string.position}%`,
              bottom: isRotated ? 0 : undefined,
              height: isRotated ? '100%' : `${string.size}px`,
              width: isRotated ? `${string.size}px` : '100%',
              backgroundColor: theme.stringColor,
            }}
          />
        ))}
      </div>
    );
  };

  const Nodes = ({ numStrings, numFrets, isRotated, fretPositionsAndSizes, theme }) => {
    const nodes = [];

    for (let i = 0; i < numFrets; i++) {
      for (let j = 0; j < numStrings; j++) {
        const fret = fretPositionsAndSizes[i];
        if (fret) {
          const nodeWrapperStyle = {
            position: 'absolute',
            left: isRotated ? `${(j / (numStrings - 1)) * 100}%` : `${fret.position}%`,
            top: isRotated ? `${fret.position}%` : `${(j / (numStrings - 1)) * 100}%`,
          };

          const horizontalLineStyle = {
            position: 'absolute',
            width: '20px',
            height: '0.5px',
            backgroundColor: theme.nodeColor,
            transform: 'translate(-50%, -50%)',
          };

          const verticalLineStyle = {
            position: 'absolute',
            width: '0.5px',
            height: '20px',
            backgroundColor: theme.nodeColor,
            transform: 'translate(-50%, -50%)',
          };

          nodes.push(
            <div key={`node-${i}-${j}`} style={nodeWrapperStyle}>
              <div style={horizontalLineStyle}></div>
              <div style={verticalLineStyle}></div>
            </div>
          );
        }
      }
    }

    return nodes;
  };

  const fretLabelStyle = (index) => {
    if (!fretPositionsAndSizes[index] || fretsList[index] === null) return { display: 'none' };

    const fret = fretPositionsAndSizes[index];
    const centerPosition = fret.position;

    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '30px',
      position: 'absolute',
      left: isRotated ? undefined : `${isLeftHanded ? 100 - centerPosition : centerPosition}%`,
      top: isRotated ? `${centerPosition}%` : undefined,
      transform: isRotated ? 'translateY(-50%)' : 'translateX(-50%)',
      color: theme.labelColor,
    };
  };

  const stringLabelStyle = (index) => {
    const position = (index / (strings - 1)) * 100; // Calculate position in percentage
    return {
      position: 'absolute',
      left: isRotated ? `${position}%` : undefined,
      top: isRotated ? undefined : `${position}%`,
      transform: isRotated ? 'translateX(-50%)' : 'translateY(-50%)',
      color: theme.labelColor,
    };
  };

  const fretsList = showNut ? ['0', ...Array.from({ length: frets }, (_, i) => i + 1)] : [null, ...Array.from({ length: frets }, (_, i) => i + 1)];
  const stringsList = Array.from({ length: strings }, (_, i) => isRotated ? strings - i : i + 1);

  const ThinStringSideContainer = () => (
    <div style={{ ...cellStyle, ...positions['Thin String Side Container'], minHeight: LabelVisible ? "60px" : "30px", minWidth: LabelVisible ? "60px" : "30px", flexDirection: isRotated ? 'column' : 'row', position: 'relative' }} onClick={() => setShowFretLabelsThinside(!showFretLabelsThinside)}> 
      {showFretLabelsThinside && fretsList.map((fret, index) => (
        <div key={fret} style={fretLabelStyle(index)}>{fret}</div>
      ))}
    </div>
  );

  const ThickStringSideContainer = () => (
    <div style={{ ...cellStyle, ...positions['Thick String Side Container'], minHeight: LabelVisible ? "60px" : "30px", minWidth: LabelVisible ? "60px" : "30px", flexDirection: isRotated ? 'column' : 'row', position: 'relative' }} onClick={() => setShowFretLabelsThickside(!showFretLabelsThickside)}>
      {showFretLabelsThickside && fretsList.map((fret, index) => (
        <div key={fret} style={fretLabelStyle(index)}>{fret}</div>
      ))}
    </div>
  );

  const NutSideContainer = (
    <div style={{ ...cellStyle, ...positions['Nut Side Container'], minWidth: LabelVisible ? "60px" : "30px", minHeight: LabelVisible ? "60px" : "30px", flexDirection: isRotated ? 'row' : 'column', position: 'relative' }} onClick={() => setShowStringLabelsNutside(!showStringLabelsNutside)}>
      {strings !== 1 && showStringLabelsNutside && stringsList.map((string, index) => (
        <div key={index} style={stringLabelStyle(index)}>{string}</div>
      ))}
    </div>
  );

  const BridgeSideContainer = (
    <div style={{ ...cellStyle, ...positions['Bridge Side Container'], minWidth: LabelVisible ? "60px" : "30px", minHeight: LabelVisible ? "60px" : "30px",  flexDirection: isRotated ? 'row' : 'column', position: 'relative' }} onClick={() => setShowStringLabelsBridgeside(!showStringLabelsBridgeside)} >
      {strings !== 1 && showStringLabelsBridgeside && stringsList.map((string, index) => (
        <div key={index} style={stringLabelStyle(index)}>{string}</div>
      ))}
    </div>
  );

  const TopRightCornerContainer = (
    <div style={topRightCellStyle}>
            <FontAwesomeIcon icon={isRotated ? faRotateReverse : faRotate} onClick={toggleRotation} />
    </div>
  );

  const FretboardContainer = (
    <div style={{ ...cellStyle, ...centerCellStyle }}>
      <div ref={fretboardRef} style={{
        position: 'relative',
        width: isRotated ? (strings === 1 ? '1px' : '100%') : '100%',
        height: isRotated ? '100%' : (strings === 1 ? '1px' : '100%'),
        display: 'flex',
        flexDirection: isRotated ? 'column' : 'row',
      }}>
        {fretPositionsAndSizes.map((fret, index) => (
          <div
            key={index}
            ref={el => fretRefs.current[index] = el}
            style={{
              position: 'absolute',
              left: isRotated ? '0' : `${isLeftHanded ? 100 - fret.position : fret.position}%`,
              top: isRotated ? `${fret.position}%` : '0',
              width: isRotated ? '100%' : `${index === 0 && showNut ? fretSize * 5 : fretSize}px`,
              height: isRotated ? `${index === 0 && showNut ? fretSize * 5 : fretSize}px` : '100%',
              backgroundColor: theme.fretColor,
            }}
          ></div>
        ))}
        <Strings numStrings={strings} isRotated={isRotated} theme={theme} />
        {showNodes && <Nodes numStrings={strings} numFrets={frets} isRotated={isRotated} fretPositionsAndSizes={fretPositionsAndSizes} theme={theme} />}
      </div>
    </div>
  );

  return (
    <div style={appContainerStyle(isStacked)}>
      <div style={contentStyle(isStacked)}>
        <div style={diagramFrameStyle(theme)}>
          <div style={gridStyle}>
            <ThinStringSideContainer />
            {NutSideContainer}
            {FretboardContainer}
            {BridgeSideContainer}
            <ThickStringSideContainer />
            {TopRightCornerContainer}
          </div>
        </div>
        <div style={resizerStyle(theme, isStacked)}><FontAwesomeIcon icon={isStacked ? faGripLines : faGripLinesVertical} /></div>
        <div ref={controlPanelRef} style={controlPanelStyle(theme, isStacked, panelSize)}>
          {window.innerWidth > 550 && (
            <div style={layoutButtonStyle}>
              <button style={buttonStyle(theme)} onClick={toggleLayout}>
                <FontAwesomeIcon icon={faTableLayout} />
              </button>
            </div>
          )}
          <div style={controlPanelGroup}>
            <button style={buttonStyle(theme)} onClick={toggleShowNut}>
              {showNut ? 'Hide Nut' : 'Show Nut'}
            </button>
            <button style={buttonStyle(theme)} onClick={toggleShowNodes}>
              {showNodes ? 'Hide Nodes' : 'Show Nodes'}
            </button>
            <button style={buttonStyle(theme)} onClick={toggleLeftHanded}>
              {isLeftHanded ? 'Right Handed' : 'Left Handed'}
            </button>
            <button style={buttonStyle(theme)} onClick={toggleTheme}>
              <FontAwesomeIcon icon={faMoon} /> Theme
            </button>
          </div>
          <div style={controlPanelGroup}>
            <label>
              Frets: {frets}
              <input
                type="range"
                min="3"
                max="24"
                value={frets}
                onChange={handleFretsChange}
                style={sliderStyle}
              />
            </label>
            <label>
              Strings: {strings}
              <input
                type="range"
                min="1"
                max="8"
                value={strings}
                onChange={handleStringsChange}
                style={sliderStyle}
              />
            </label>
            <label>
              Thickness: {stringSizeBase}
              <input
                type="range"
                min="0.5"
                max="5"
                step="0.5"
                value={stringSizeBase}
                onChange={handleStringSizeBaseChange}
                style={sliderStyle}
              />
            </label>
          </div>
        </div>
      </div>
      <header style={headerStyle(theme, isStacked)}>
        <FontAwesomeIcon icon={faWrench} />
        <FontAwesomeIcon icon={faMusic} />
        <FontAwesomeIcon icon={faShapes} />
        <FontAwesomeIcon icon={faPen} />
        <FontAwesomeIcon icon={faPlay} />
    </header>
    </div>
  );
};

export default App;
